import React from "react"
import { Link } from "gatsby"

import PaddedContainer from "../ui/PaddedContainer"
import { PaperBox } from "../ui/PaperBox"
import SimpleContent from "../sections/SimpleContent"

const LoggedOut = () => {
  return (
    <PaddedContainer align="center">
      <SimpleContent
        id="loggedout"
        core={{
          subtitle: "You've successfully logged out.",
          animate: false,
          titleAlign: "center",
          divider: "topGradientLine",
        }}
        content={`<p>
        Return to the [login page](/login).
      </p>`}
        align="center"
      />
    </PaddedContainer>
  )
}

export default LoggedOut
