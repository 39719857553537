import React, { useEffect, useState } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import styled from "styled-components"

import LoggedOut from "../components/auth/LoggedOut"

import { CircularProgress } from "@material-ui/core"
import { useAuthState } from "../contexts/authContext"

const LoggingOut = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 9999999;
  h2 {
    margin-bottom: 3rem;
  }
  p {
    margin-top: 2rem;
  }
`

const LoggedOutPage = () => {
  const state = useAuthState()
  const [loggingOut, setLoggingOut] = useState(true)

  useEffect(() => {
    setLoggingOut(state.isLoggedIn())
  }, [state])

  return (
    <Layout hasHero={false}>
      <SEO title="You've logged out" />
      {loggingOut && (
        <LoggingOut>
          <h2>Logging Out</h2>

          <CircularProgress />
          <p className="large">
            <em>Please wait...</em>
          </p>
        </LoggingOut>
      )}
      {!loggingOut && <LoggedOut />}
    </Layout>
  )
}

export default LoggedOutPage
